import Encabezado from "../componentes/Encabezado";
import Title from "../componentes/Title";
import IconPage from "../componentes/IconPage";
import futbol5 from '../img/futbol5_dos.svg'
import imgInfo from '../img/imgInfo.png'
import Footer from "../componentes/Footer";
import IconosVolver from "../componentes/IconosVolver";
import IconoMixto from "../componentes/IconoMixto"
import '../App.css'

const Futbol = () => {


  return (
    <>
      <IconosVolver to={'/'} />
      <Encabezado />
      <IconPage image={futbol5} />
      <IconoMixto/>    
      <Footer texto={'federación argentina del trabajador de las universidades nacionales'} />


    </>
  )
}

export default Futbol

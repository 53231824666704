import { useEffect, useState } from "react";
import "../App.css";
import { showPostToForo } from "./scrappingFront";

import loadingGif from "../img/loading.gif";

function PosAjedrez() {
  const [loading, setLoading] = useState(false);
  const [sheets, setSheets] = useState([]);

  useEffect(() => {
    const loadTablesGoogleSheet = async () => {
      setLoading(true);

      await showPostToForo()
        .then(({ status, payload }) => {
          if (status === 200) {
            setSheets(payload);
            setLoading(false);
          }
        })
        .catch(({ status, payload }) => {
          console.log({ status, payload });
          setLoading(false);
        });

      setLoading(false);
    };

    loadTablesGoogleSheet();
  }, []);

  return (
    <main className="main">
      <hr />
      {loading ? (
        <img src={loadingGif} alt="loading" />
      ) : (
        // JSON.stringify(sheets.enero)
        <table className="table" style={{ width: "100%" }} border={0}>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nombre</th>
              <th>pts</th>
            </tr>
          </thead>
          <tbody>
            {sheets.posajedrez &&
              sheets.posajedrez.tableList.map((e, k) => (
                <tr key={k}>
                  <td>{e.id}</td>
                  <td>{e.nombre}</td>
                  <td>{e.pts}</td>  
                </tr>
              ))}
          </tbody>
        </table>
        
        
      )}
    </main>
  );
}

export default PosAjedrez;
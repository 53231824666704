import HoraCharla from './HoraCharla'
import DetalleCharla from './DetalleCharla'
import Disertante from './Disertante'
import clock from '../img/clock.svg'
import '../App.css'
import { Link } from 'react-router-dom'


const ItinerarioContainer = () => {
    return (
        <div className='mb-6'>
            <div className='mb-4 mt-6'>
                <div className="mt-4 poppins-bold gridPrograma">
                    <img src={clock} style={{ maxWidth: '1.8rem', margin: 'auto' }} />
                    <p className='color is-size-4-desktop is-size-6-mobile'>Martes 9 de mayo</p>
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'11:00'} />
                <div>
                    <DetalleCharla tema={'Llegada de las Delegaciones a la Unidad Turística N° 2 Embalse.'} />
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'12:30'} />
                <div>
                    <DetalleCharla tema={'Almuerzo en Comedor Unidad Turística N° 2 Embalse.'} />
                    en dos turnos.
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'16:00'} />
                <div>
                    <DetalleCharla tema={'Acreditación de las Delegaciones.'} />
                    Complejo Polideportivo Héroes de Malvinas.
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'18:00'} />
                <div>
                    <DetalleCharla tema={'Acto de Apertura.'} />
                    Complejo Polideportivo Héroes de Malvinas.
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'20:30'} />
                <div>
                    <DetalleCharla tema={'Cena Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='mb-4 mt-6'>
                <div className="mt-4 poppins-bold gridPrograma">
                    <img src={clock} style={{ maxWidth: '1.8rem', margin: 'auto' }} />
                    <p className='color is-size-4-desktop is-size-6-mobile'>Miercoles 10 de mayo</p>
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'08:00'} />
                <div>
                    <DetalleCharla tema={'Desayuno en Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'10:00'} />
                <div>
                    <DetalleCharla tema={'Inicio de Actividades Deportivas.'} />
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'12:30'} />
                <div>
                    <DetalleCharla tema={'Almuerzo en Comedor Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'14:00'} />
                <div>
                    <DetalleCharla tema={'Reinicio de Actividades Deportivas.'} />
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'20:30'} />
                <div>
                    <DetalleCharla tema={'Cena Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='mb-4 mt-6'>
                <div className="mt-4 poppins-bold gridPrograma">
                    <img src={clock} style={{ maxWidth: '1.8rem', margin: 'auto' }} />
                    <p className='color is-size-4-desktop is-size-6-mobile'>Jueves 11 de mayo</p>
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'8:00'} />
                <div>
                    <DetalleCharla tema={'Desayuno en Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'10:00'} />
                <div>
                    <DetalleCharla tema={'Inicio de Actividades Deportivas.'} />
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'11:00'} />
                <div>
                    <DetalleCharla tema={'Ciclismo Ruta.'} />
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'12:30'} />
                <div>
                    <DetalleCharla tema={'Almuerzo en Comedor Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'14:00'} />
                <div>
                    <DetalleCharla tema={'Reinicio de Actividades Deportivas.'} />
                </div>
            </div>


            <div className='gridPrograma'>
                <HoraCharla hora={'20:30'} />
                <div>
                    <DetalleCharla tema={'Cena Unidad Turística N° 2 Embalse.'} />
                </div>
            </div>


            <div className='gridPrograma' >
                <HoraCharla hora={'22:00'} />
                <div>
                    <DetalleCharla tema={'Cena - Cierre del Congreso.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='mb-4 mt-6'>
                <div className="mt-4 poppins-bold gridPrograma">
                    <img src={clock} style={{ maxWidth: '1.8rem', margin: 'auto' }} />
                    <p className='color is-size-4-desktop is-size-6-mobile'>Viernes 12 de mayo</p>
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'8:00'} />
                <div>
                    <DetalleCharla tema={'Desayuno en Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'10:00'} />
                <div>
                    <DetalleCharla tema={'Inicio de Actividades Deportivas.'} />
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'12:30'} />
                <div>
                    <DetalleCharla tema={'Almuerzo en Comedor Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'14:00'} />
                <div>
                    <DetalleCharla tema={'Reinicio de Actividades Deportivas.'} />
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'14:30'} />
                <div>
                    <DetalleCharla tema={'Cliclismo MTB.'} />
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'20:30'} />
                <div>
                    <DetalleCharla tema={'Cena Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='mb-4 mt-6'>
                <div className="mt-4 poppins-bold gridPrograma">
                    <img src={clock} style={{ maxWidth: '1.8rem', margin: 'auto' }} />
                    <p className='color is-size-4-desktop is-size-6-mobile'>Sabado 13 de mayo</p>
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'8:00'} />
                <div>
                    <DetalleCharla tema={'Desayuno en Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'09:00'} />
                <div>
                    <DetalleCharla tema={'Inicio de Actividades Deportivas.'} />
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'12:30'} />
                <div>
                    <DetalleCharla tema={'Almuerzo en Comedor Unidad Turística N° 2 Embalse.'} />
                    En dos turnos.
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'15:00'} />
                <div>
                    <DetalleCharla tema={'Maratón 10K'} />
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'20:00'} />
                <div>
                    <DetalleCharla tema={'Entrega de trofeos, medallas, certificados y Cena Show.'} />
                    Hotel Evita - Santa Rosa de Calamuchita.
                </div>
            </div>

            <div className='mb-4 mt-6'>
                <div className="mt-4 poppins-bold gridPrograma">
                    <img src={clock} style={{ maxWidth: '1.8rem', margin: 'auto' }} />
                    <p className='color is-size-4-desktop is-size-6-mobile'>Domingo 14 de mayo</p>
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'8:00'} />
                <div>
                    <DetalleCharla tema={'Desayuno en Unidad Turística N° 2 Embalse.'} />
                </div>
            </div>

            <div className='gridPrograma'>
                <HoraCharla hora={'10:00'} />
                <div>
                    <DetalleCharla tema={'Regreso de las Delegaciones.'} />
                </div>
            </div>

        </div>
    )
}

export default ItinerarioContainer

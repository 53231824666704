import FixturPadel from '../exel/FixturPadel';
import ResultPadel from '../exel/ResultPadel';
import PosPadel from '../exel/PosPadel';
import '../App.css';
import React, { useState, useEffect} from 'react';
import pdf from '../img/Reglamento-Padel-OLIMPIADAS.pdf';

const IconoPadel = () => {
    const [Fixpad, setFixpad] = useState(false);
    const [Resulpad, setResulpad] = useState(false);
    const [Pospad, setPositionpad] = useState(false);
    const [compad, setComplejopad] = useState(false);

    const handleButtonClick = () => {
        setFixpad(true);
        setResulpad(false);
        setPositionpad(false);
        setComplejopad(false);
    };
    const handleButtonResul = () => {
        setFixpad(false);
        setResulpad(true);
        setPositionpad(false);
        setComplejopad(false);
    };
    const handleButtonPos = () => {
        setFixpad(false);
        setResulpad(false);
        setPositionpad(true);
        setComplejopad(false);
    };
    const handleButtonCom = () => {
        setFixpad(false);
        setResulpad(false);
        setPositionpad(false);
        setComplejopad(true);
    };

    useEffect(() => {
        handleButtonPos();
    }, []);

    return (
        <>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonPos}>Cruces</button>
                <button className='tab-principal button is-small' onClick={handleButtonResul}>Resultados</button>
                <button className='tab-principal button is-small' onClick={handleButtonClick}>Fixture</button>
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Reglamento</a>
                </button>
            </div>
            {Fixpad && <FixturPadel />}
            {Resulpad && <ResultPadel />}
            {Pospad && <PosPadel />}
        </>
    )
}

export default IconoPadel
import Encabezado from "../componentes/Encabezado";
import IconosHome from "../componentes/IconosHome";
import IconoDetalle from "../componentes/IconoDetalle";
import fondo from '../img/fondo-50.svg'
import { Layout } from "../layout";


const Home = () => {

  return (
    <Layout backgroundHeader={fondo} textFooter={'federación argentina del trabajador de las universidades nacionales'}>
      <Encabezado/>
      <IconosHome />
      <IconoDetalle/>
    </Layout>
  )
}

export default Home;















import futbol11 from '../img/futbol11.svg'
import futbol5 from '../img/futbol5.svg'
import voley from '../img/voley.svg'
import padel from '../img/padel.svg'
import maraton from '../img/maraton.svg'
import ciclismoRuta from '../img/CiclismoRuta.svg'
import ciclismoMT from '../img/Ciclismomtb.svg'
import ajedrez from '../img/ajedrez.svg'
import panelistas from '../img/image.svg'
import ubicacion from '../img/ubicacion.svg'
import '../App.css'
import { Link } from 'react-router-dom'

const IconosHome = () => {
  return (
    <>
      <div className="columns is-mobile has-text-centered">
        <div className="column">
          <h1 className='title-home'>Actividades</h1>
        </div>
      </div>

      <div className="holster">
        <div className="containersnap x mandatory-scroll-snapping" dir="ltr">
          <div>
            <div className='m-1' style={{ cursor: 'pointer' }}>
              <Link to='/futbol11'> <img src={futbol11} alt="" className='p-1 icon-responsive' /> </Link>
            </div>
          </div>
          <div>
            <div className='m-1' style={{ cursor: 'pointer' }}>
              <Link to='/futbol5'><img src={futbol5} alt="" className='p-1 icon-responsive' /> </Link>
            </div>
          </div>
          <div>
            <div className='m-1' style={{ cursor: 'pointer' }}>
              <Link to='/voley'><img src={voley} alt="" className='p-1 icon-responsive' /> </Link>
            </div>
          </div>
          <div>
            <div className='m-1' style={{ cursor: 'pointer' }}>
              <Link to='/padel'><img src={padel} alt="" className='p-1 icon-responsive' /> </Link>
            </div>
          </div>
          <div>
            <div className='m-1' style={{ cursor: 'pointer' }}>
              <Link to='/maraton'><img src={maraton} alt="" className='p-1 icon-responsive' /> </Link>
            </div>
          </div>
          <div>
            <div className='m-1' style={{ cursor: 'pointer' }}>
              <Link to='/ciclismo'><img src={ciclismoRuta} alt="" className='p-1 icon-responsive' /> </Link>
            </div>
          </div>
          <div>
            <div className='m-1' style={{ cursor: 'pointer' }}>
              <Link to='/ciclismoMTB'><img src={ciclismoMT} alt="" className='p-1 icon-responsive' /> </Link>
            </div>
          </div>
          <div>
            <div className='m-1' style={{ cursor: 'pointer' }}>
              <Link to='/ajedrez'><img src={ajedrez} alt="" className='p-1 icon-responsive' /> </Link>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default IconosHome;


import IconPage from "../componentes/IconPage";
import ItinerarioContainer from "../componentes/ItinerarioContainer";
import futbol from '../img/futbol11_dos.svg'
import { Layout } from "../layout";
import IconosVolver from "../componentes/IconosVolver";
import { useState, useEffect } from "react";
import Encabezado from "../componentes/Encabezado";
import Sheets from "../exel/Sheets";
import IconoResult from "../componentes/IconoResult"


const Futbol = ({image, indice, height}) => { 
     
    return (    
        <>
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
          <Encabezado/>
          <IconPage image={futbol} />
          <IconoResult/>
          <IconosVolver to={'/'}/>
        </Layout>

        </>     
    )
}


export default Futbol;
// BULMA
import 'bulma/css/bulma.min.css'
// PAGES
import Home from "./pages/Home";
import Futbol11 from "./pages/Futbol11";
import Futbol5 from "./pages/Futbol5";
import Voley from "./pages/Voley";
import Padel from "./pages/Padel";
import Maraton from "./pages/Maraton";
import CiclismoRuta from "./pages/CiclismoRuta";
import CiclismoMTB from './pages/CiclismoMTB';
import Ajedrez from "./pages/Ajedrez";

import HomePageTmp from './pages/HomePageTmp'
import ListaPanelistas from "./pages/ListaPanelistas";
import Panelista from "./pages/Panelista";
import Ubicacion from "./pages/Ubicacion";
import Papa from "papaparse";

// RECT-ROUTER
import {BrowserRouter, Routes,Route} from 'react-router-dom';
import HomePage from './pages/HomePageTmp';
import Wifi from './pages/Wifi';
import Programa from './pages/Programa';
import ComplejoYHoteles from './pages/ComplejoYHoteles';

function App() {
  Papa.parse('{"name":"facundo"}',{
    download:false,
    header:true,
    complete:(e)=>console.log(e)
  })
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/wifi' element={<Wifi />} />
        <Route path='/futbol11' element={<Futbol11 />} />        
        <Route path='/futbol5' element={<Futbol5 />} />   
        <Route path='/Voley' element={<Voley/>}/> 
        <Route path='/Padel' element={<Padel/>}/>  
        <Route path='/Maraton' element = {<Maraton/>}/>
        <Route path='/Ciclismo' element = {<CiclismoRuta/>}/>
        <Route path='/CiclismoMTB' element={<CiclismoMTB/>}/>
        <Route path='/Ajedrez' element = {<Ajedrez/>}/> 
        <Route path='/panelista/:nombre' element={<Panelista />} />        
        <Route path='/panelistas' element={<ListaPanelistas />} />        
        <Route path='/ubicacion' element={<Ubicacion />} />       
        <Route path='/homepage' element={<HomePageTmp />} />
        <Route path='/programa' element={<Programa />} />
        <Route path='/ComplejoYHoteles' element={<ComplejoYHoteles />} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;

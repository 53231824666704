import GoogleSheets from "./GoogleSheets.json";

import Papa from "papaparse";

export const getFutbolOnce = () => {
  return new Promise((resolve, reject) => {
    const handleComplete = (res) => {
      if (res?.errors.length > 0) {
        reject("Error al obtener información de la base de datos.");
      } else if (res?.data.length > 0) {
        resolve(res.data);
      } else {
        reject("Error al procesar la consulta.");
      }
    };
    Papa.parse(GoogleSheets.URL_FUTBOL_11, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: handleComplete,
    });
  });
};

export const httpGet = (theUrl) => {
  let xmlHttp = null;
  xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", theUrl, false);
  xmlHttp.send(null);
  return xmlHttp.responseText;
};
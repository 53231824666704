import '../App.css';
import PosRuta from '../exel/PosRuta';
import ParRuta from '../exel/ParRuta';
import PosRutaFem from '../exel/PosRutaFem';
import ParRutaFem from '../exel/ParRutaFem';
import React, { useState, useEffect } from 'react';
import pdf from '../img/Guia-Ciclismo-OLIMPIADAS.pdf';

const IconoCiclismo = () => {
    const [PosRut, setPosRut] = useState(false);
    const [ParRut, setParRut] = useState(false);
    const [PosRutf, setPosRutf] = useState(false);
    const [ParRutf, setParRutf] = useState(false);

    const handleButtonRut = () => {
        setPosRut(true);
        setParRut(false);

    };
    const handleButtonPR = () => {
        setPosRutf(true);
        setParRutf(false);

    };
    const handleButtonPRut = () => {
        setPosRut(false);
        setParRut(true);
    };
    const handleButtonPRF = () => {
        setPosRutf(false);
        setParRutf(true);
    };
    useEffect(() => {
        handleButtonRut();
        handleButtonPR();
    }, []);

    return(
        <>
             <h1 className='has-text-centered is-size-4 m-3'>Masculino</h1>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonRut}>Posiciones</button>
                {/* <button className='tab-principal button is-small' onClick={handleButtonPRut}>Participantes</button> */}
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Guía</a>
                </button>
            </div>
            {PosRut && <PosRuta />}
            {ParRut && <ParRuta />}
            
            <h1 className='has-text-centered is-size-4 m-3'>Femenino</h1>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonPR}>Posiciones</button>
                {/* <button className='tab-principal button is-small' onClick={handleButtonPRF}>Participantes</button> */}
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Guía</a>
                </button>
            </div>
            {PosRutf && <PosRutaFem />}
            {ParRutf && <ParRutaFem />}
            

        </>
    )
}

export default IconoCiclismo;
import FixtVoley from '../exel/FixtVoley';
import ResultVoley from '../exel/ResultVoley';
import PositionVoley from '../exel/PositionVoley';
import '../App.css';
import React, { useState, useEffect } from 'react';
import pdf from '../img/Reglamento-Voley-OLIMPIADAS.pdf';

const IconoVoley = () => {
    const [Fixvol, setFixvol] = useState(false);
    const [Resulvol, setResulvol] = useState(false);
    const [Posvol, setPositionvol] = useState(false);
    const [comvol, setComplejovol] = useState(false);

    const handleButtonClick = () => {
        setFixvol(true);
        setResulvol(false);
        setPositionvol(false);
        setComplejovol(false);
    };
    const handleButtonResul = () => {
        setFixvol(false);
        setResulvol(true);
        setPositionvol(false);
        setComplejovol(false);
    };
    const handleButtonPos = () => {
        setFixvol(false);
        setResulvol(false);
        setPositionvol(true);
        setComplejovol(false);
    };

    useEffect(() => {
        handleButtonResul();
    }, []);

    return (
        <>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonResul}>Resultados</button>
                <button className='tab-principal button is-small' onClick={handleButtonClick}>Fixture</button>
                <button className='tab-principal button is-small' onClick={handleButtonPos}>Posiciones</button>
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Reglamento</a>
                </button>
            </div>
            {Fixvol && <FixtVoley />}
            {Resulvol && <ResultVoley />}
            {Posvol && <PositionVoley />}
            
        </>
    )
}

export default IconoVoley
import { httpGet } from "./helpers";
import GoogleSheets from "./GoogleSheets.json";

export const showPostToForo = () => {
  let tablesName = {};

  const hiddenHtml = document.createElement("html");
  hiddenHtml.innerHTML = httpGet(GoogleSheets.URL_COMPLETO);

  const sheetButtons = hiddenHtml.querySelectorAll("li[id]");
  if (sheetButtons.length > 0) {
    sheetButtons.forEach((e) => {
      const key = e?.id.replace("sheet-button-", ""); // el id interno
      const value = e.childNodes[0].innerHTML; // nombre de la hoja / tabla

      tablesName = { ...tablesName, [key]: value };
    });
  }

  let tableResult = {};

  const tables = hiddenHtml.querySelector("#sheets-viewport");

  if (tables) {
    tables.childNodes.forEach((table) => {
      const headers = [];
      // Extraccion del nombre de las columnas
      const trs = table.querySelectorAll("tr");
      const firstRow = trs[1].querySelectorAll("td");
      firstRow.forEach((e) => {
        headers.push(e.innerHTML);
      });
      // Tabla / Hoja actual
      const currentTableName = String(tablesName[table.id]).toLocaleLowerCase();
      if (trs.length > 1) {
        const tableList = [];

        for (let i = 2; i < trs.length; i++) {
          const rows = trs[i].querySelectorAll("td");
          if (rows.length === headers.length) {
            let object = {};

            for (let i = 0; i < headers.length; i++) {
              const key = String(headers[i]).toLocaleLowerCase();
              const value = rows[i].innerHTML;

              object = { ...object, [key]: value };
            }

            tableList.push(object);
          }
        }


        // [
        //     "enero": {tableHeader: headers, tableList}
        // ]

        const object = { tableHeader: headers, tableList }

        tableResult = {...tableResult, [currentTableName]: object}

      }
    });
  }

  return new Promise((resolve, reject) => {
    if (Object.values(tableResult).length > 0) {
      resolve({
        status: 200,
        payload: tableResult,
      });
    } else {
      reject({
        status: 500,
        payload: "Error al cargar el excel",
      });
    }
  });
};
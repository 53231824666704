import IconPage from "../componentes/IconPage";
import voley from "../img/voley_dos.svg"
import { Layout } from "../layout";
import Encabezado from "../componentes/Encabezado";
import IconosVolver from "../componentes/IconosVolver";
import IconoVoley from "../componentes/IconoVoley";

const Voley = ({image, indice, height}) =>{

    return(
        <>
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'} >
        <Encabezado/>
        <IconPage image={voley}/>
        <IconoVoley/>
        <IconosVolver to={'/'}/>
        </Layout>
        </>
    )
}

export default Voley
import HoraCharla from './HoraCharla'
import DetalleCharla from './DetalleCharla'
import Disertante from './Disertante'
import clock from '../img/clock.svg'
import '../App.css'
import { Link } from 'react-router-dom'


const ItinerarioHoteles = () => {

  const style = {
    button: {
        color: '#fff',
        padding: '.4rem',
        background: '#3EC3FF',
        width: '60%',
        border: 'none',
        fontSize: '1.5rem'
    }
}
  return (
    <div className='m-1'>

      <div className="mapIframe">
        <h2 className="titleHotel mt-6">Embalse Hotel 2</h2>
        <h3 className="mb-4"><strong>Las Acacias, Embalse.</strong></h3>
      </div>

      <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
        <a href='https://www.google.com/maps/dir//Embalse+Hotel+2+Las+Acacias,+Embalse,+C%C3%B3rdoba/@-32.209317,-64.423239,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x95d29eb64c65786f:0x814214f28748f111!2m2!1d-64.423239!2d-32.209317' target="_blank">
          <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
        </a>
      </div>

      <div className="mapIframe">
        <h2 className="titleHotel mt-6">Hotel Avenida</h2>
        <h3 className="mb-4"><strong>H. Yrigoyen 174, Embalse.</strong></h3>
      </div>

      <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
        <a href='https://www.google.com/maps/dir//Hotel+Avenida,+Hipolito+Yrigoyen+174,+X5856+Embalse,+C%C3%B3rdoba/@-32.2050817,-64.4005087,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x95d29f270ec651f5:0x53f41ae7a11db018!2m2!1d-64.4005087!2d-32.2050817' target="_blank">
          <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
        </a>
      </div>

      <div className="mapIframe">
        <h2 className="titleHotel mt-6">Hostal del Lago</h2>
        <h3 className="mb-4"><strong>Cabo 1° Huanca esq. 9 de Julio, Embalse</strong></h3>
      </div>

      <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
        <a href='https://www.google.com/maps/dir//Hostal+Del+Lago,+Cabo+1%C2%B0+Huanca,+9+de+Julio+esq,+Embalse,+C%C3%B3rdoba/@-32.2067092,-64.4000199,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x95d29e93ce033f59:0xbe3f74192eadc341!2m2!1d-64.4000199!2d-32.2067092' target="_blank">
          <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
        </a>
      </div>

      <div className="mapIframe">
        <h2 className="titleHotel mt-6">Hotel 4 Ases</h2>
        <h3 className="mb-4"><strong>Ricardo Balvin 104, Embalse</strong></h3>
      </div>

      <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
        <a href='https://www.google.com/maps/dir//Hotel+4+Ases,+Ricardo+Balvin+104,+X5856+Embalse,+C%C3%B3rdoba/@-32.2072838,-64.3988014,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x95d29e922947b205:0x349360eef65d7bbe!2m2!1d-64.3988014!2d-32.2072838' target="_blank">
          <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
        </a>
      </div>
      
      <div className="mapIframe">
        <h2 className="titleHotel mt-6">Hotel Evita</h2>
        <h3 className="mb-4"><strong>Ruta 5 Km 90, Santa Rosa de Calamuchita</strong></h3>
      </div>

      <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
        <a href='https://www.google.com/maps/dir//Hotel+Evita,+RP5+Km+90,+X5196+Santa+Rosa+de+Calamuchita,+C%C3%B3rdoba/@-32.0737046,-64.5299132,15.75z/data=!4m9!4m8!1m0!1m5!1m1!1s0x9432986f9eb90f67:0x240ff30b163ee7be!2m2!1d-64.5261624!2d-32.0706139!3e0' target="_blank">
          <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
        </a>
      </div>

    </div>



  )
}

export default ItinerarioHoteles

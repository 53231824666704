import IconPage from "../componentes/IconPage";
import maraton from "../img/maraton_dos.svg";
import { Layout } from "../layout";
import Encabezado from "../componentes/Encabezado";
import IconosVolver from "../componentes/IconosVolver";
import IconoMaraton from "../componentes/IconoMaraton";
const Maraton = ({image, indice, height}) =>{

    return (
        <>
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'} >
        <Encabezado/>
        <IconPage image={maraton}/>
        <IconoMaraton/>
        <IconosVolver to ={'/'}/>
        </Layout>
        </>
    )
}

export default Maraton
import face from '../img/facebook-2 1.svg'
import inst from '../img/instagram 1.svg'
import { Link } from 'react-router-dom'

import iconDir from '../img/chevron-left 1.svg'
const IconoDetalle = () => {
	const style = {
		lineal: {
			borderTop: '1px solid #BDBDBD',
			//position: 'fixed',
			bottom: '0',
			left: '0',
			right: '0',
			width: '90%',
			margin: 'auto',
			padding: '1rem 0',
			textAlign: 'center',
			background: '#fff',
		},
	}
	return (
		<>
			<div style={style.lineal}>

				<ul className="title has-text-centered ">
					<Link to='/programa'><li className='mt-4 poppins-italic poppins-bold is-size-5 has-text-black'>Programa General
						<img className='icon-right' src={iconDir} /> </li><br></br>
					</Link>
					<Link to='/ComplejoYHoteles'><li className='poppins-italic poppins-bold is-size-5 has-text-black'>Hoteles y Complejos
						<img className='icon-right' src={iconDir} /> </li><br></br></Link>
					<a href='https://galerias.fatun.org.ar' target="_blank"><li className='poppins-italic poppins-bold is-size-5 has-text-black'>Ver galerías
						<img className='icon-right' src={iconDir} /> </li></a> <br></br>
					{/* <Link to='/wifi'><li className='poppins-italic poppins-bold is-size-5 has-text-black'>Conexión Wifi <img src={iconDir} /> </li><br></br></Link> */}
					<a href='http://www.fatun.org.ar' target="_blank"><li className='poppins-italic poppins-bold is-size-5 has-text-black'>Ir al sitio
						<img className='icon-right' src={iconDir} /> </li></a><br></br>
				</ul>
			</div>

			<div className="columns is-mobile has-text-centered">
				<div className="column has-text-right">
					<a href='https://www.facebook.com/fatun.oficial' target="_blank"> <img src={face} alt="" className='p-1 icon-responsive' /> </a>
				</div>
				<div className="column has-text-left">
					<a href="https://www.instagram.com/fatun.oficial/" target="_blank" >
						<img src={inst} alt="" className='p-1 icon-responsive' />
					</a>
				</div>
			</div>


		</>
	)
}
export default IconoDetalle

import { Layout } from "../layout";
import IconosVolver from "../componentes/IconosVolver";
import IconPage from "../componentes/IconPage";
import { useState, useEffect } from "react";
import Encabezado from "../componentes/Encabezado";
import IterarioComplejo from "../componentes/IterarioComplejo"
import IterarioHoteles from "../componentes/iterarioHoteles"

const ComplejoYHoteles = ({ image, indice, height }) => {


  return (
    <>
      <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
        <Encabezado />
        <div class="columns is-mobile has-text-centered">
          <div class="column">
            <h1 className='title-home'>Complejos</h1>
          </div>
        </div>
        <IterarioComplejo />
        <div class="columns is-mobile has-text-centered mt-6">
          <div class="column">
            <h1 className='title-home'>Hoteles</h1>
          </div>
        </div>
        <IterarioHoteles />
        <IconosVolver to={'/'} />
      </Layout>

    </>
  )
}


export default ComplejoYHoteles;

import futbol11 from '../img/futbol11.svg'
import '../App.css';
import { Link } from 'react-router-dom';
import Sheets from "../exel/Sheets";
import Result from "../exel/Result";
import Position from '../exel/Position';

import React, { useState, useEffect } from 'react';
import pdf from '../img/futbol_once.pdf';
// import Button from '../components/Button';


const IconoResult = () => {
    const [Fixtur, setFixtur] = useState(false);
    const [Resul, setResul] = useState(false);
    const [Pos, setPosition] = useState(false);
    const [com, setComplejo] = useState(false);

    const handleButtonClick = () => {
        setFixtur(true);
        setResul(false);
        setPosition(false);
        setComplejo(false);
    };
    const handleButtonResul = () => {
        setFixtur(false);
        setResul(true);
        setPosition(false);
        setComplejo(false);
    };
    const handleButtonPos = () => {
        setFixtur(false);
        setResul(false);
        setPosition(true);
        setComplejo(false);
    };

    useEffect(() => {
        handleButtonResul();
    }, []);


    return (
        <>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonResul}>Resultados</button>
                <button className='tab-principal button is-small' onClick={handleButtonClick}>Fixture</button>
                <button className='tab-principal button is-small' onClick={handleButtonPos}>Posiciones</button>
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Reglamento</a>
                </button>
            </div>
            {Fixtur && <Sheets />}
            {Resul && <Result />}
            {Pos && <Position />}


        </>
    )
}

export default IconoResult
import IconPage from "../componentes/IconPage";
import ciclismo from "../img/ciclismoRUTA_dos.svg"
import { Layout } from "../layout";
import Encabezado from "../componentes/Encabezado";
import IconosVolver from "../componentes/IconosVolver";
import IconoCiclismoRuta from "../componentes/IconoCiclismoRuta";

const Ciclismo = ({image,indice,height}) =>{

    return(
        <>
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
        <Encabezado/>
        <IconPage image={ciclismo}/>
        <IconoCiclismoRuta/>
        <IconosVolver to={'/'}/>
        </Layout>
        </>
    )
}

export default Ciclismo
import '../App.css';
import ParMtv from '../exel/ParMtv';
import PosMtv from '../exel/PosMtv';
import PosMtbF from '../exel/PosMtbF';
import ParMtbF from '../exel/ParMtbF';
import React, { useState, useEffect } from 'react';
import pdf from '../img/Guia-Ciclismo-OLIMPIADAS.pdf';

const IconoCiclismoMTB = () => {
    const [PosMtvm, setPosMtb] = useState(false);
    const [ParMtvm, setParMtb] = useState(false);
    const [Posmf, setPosMtbf] = useState(false);
    const [Parmf, setParMtbf] = useState(false);

    const handleButtonMTB = () => {
        setPosMtb(true);
        setParMtb(false);

    };
    const handleButtonPM = () => {
        setPosMtbf(true);
        setParMtbf(false);

    };
    const handleButtonPMTB = () => {
        setPosMtb(false);
        setParMtb(true);
    };
    const handleButtonPMF = () => {
        setPosMtbf(false);
         setParMtbf(true);
    };
    useEffect(() => {
        handleButtonMTB();
        handleButtonPM();
    }, []);
    return(
        <>
         <h1 className='has-text-centered is-size-4 m-3'>Masculino</h1>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonMTB}>Posiciones</button>
                {/* <button className='tab-principal button is-small' onClick={handleButtonPMTB}>Participantes</button> */}
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Guía</a>
                </button>
            </div>
            {PosMtvm && <PosMtv />}
            {ParMtvm && <ParMtv />}

            <h1 className='has-text-centered is-size-4 m-3'>Femenino</h1>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonPM}>Posiciones</button>
                {/* <button className='tab-principal button is-small' onClick={handleButtonPMF}>Participantes</button> */}
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Guía</a>
                </button>
            </div>
            {Posmf && <PosMtbF />}
            {Parmf && <ParMtbF />}
            
        </>
    )
}

export default IconoCiclismoMTB;
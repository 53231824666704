import IconPage from "../componentes/IconPage";
import ciclismo from "../img/ciclismoMTB_dos.svg"
import { Layout } from "../layout";
import Encabezado from "../componentes/Encabezado";
import IconosVolver from "../componentes/IconosVolver";
import IconoCiclismoMTB from "../componentes/IconoCiclismoMTB";

const CiclismoMTB = ({image,indice,height}) =>{

    return(
        <>
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
        <Encabezado/>
        <IconPage image={ciclismo}/>
        <IconoCiclismoMTB/>
        <IconosVolver to={'/'}/>
        </Layout>
        </>
    )
}

export default CiclismoMTB
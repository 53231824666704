import HoraCharla from './HoraCharla'
import DetalleCharla from './DetalleCharla'
import Disertante from './Disertante'
import clock from '../img/clock.svg'
import '../App.css'
import { Link } from 'react-router-dom'


const ItinerarioComplejo = () => {

  const style = {
    button: {
        color: '#fff',
        padding: '.4rem',
        background: '#3EC3FF',
        width: '60%',
        border: 'none',
        fontSize: '1.5rem'
    }
}
  return (
    <div className='m-1'>

      <div className="mapIframe">
        <h2 className="titleHotel mt-6">Polideportivo Embalse</h2>
        <h3 className="mb-4"><strong>Fútbol, Voley, etc.</strong></h3>
      </div>

      <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
        <a href='https://www.google.com/maps/dir//Polideportivo+EMBALSE,+Embalse,+C%C3%B3rdoba/@-32.2074749,-64.4085157,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x95d29e967902ead1:0xc16e58ea0a4851fe!2m2!1d-64.4085157!2d-32.2074749' target="_blank">
          <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
        </a>
      </div>

      <div className="mapIframe">
        <h2 className="titleHotel mt-6">García Pádel</h2>
        <h3 className="mb-4"><strong>Pádel - Cacique Yana, Embalse.</strong></h3>
      </div>

      <div style={{ margin: 'auto', textAlign: 'center' }} className='mt-2 buttonUbicacion'>
        <a href='https://www.google.com/maps/dir//Garc%C3%ADa+Padel,+Cacique+Yana,+Embalse,+C%C3%B3rdoba/@-32.2056453,-64.4036546,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x95d29f0b97523671:0x3d9cf1d2fe22678f!2m2!1d-64.4036546!2d-32.2056453' target="_blank">
          <button style={style.button} className='poppins-bold is-uppercase mt-4 mb-4 is-size-4-desktop is-size-6-mobile'>¿Cómo llegar?</button>
        </a>
      </div>


    </div>



  )
}

export default ItinerarioComplejo

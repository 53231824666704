import PosMaraton from "../exel/PosMaraton";
import ParMaraton from "../exel/ParMaraton";
import PosMaratonFem from "../exel/PosMaratonFem";
import ParMaratonFem from "../exel/ParMaratonFem";
import '../App.css';
import React, { useState, useEffect } from 'react';
import pdf from '../img/Guia-Atletismo-OLIMPIADAS.pdf';


const IconoMaraton = () => {
    const [PosMar, setPosMaraton] = useState(false);
    const [ParMar, setParMaraton] = useState(false);

    const [PosMarFem, setPosMaratonFem] = useState(false);
    const [ParMarFem, setParMaratonFem] = useState(false);

    const handleButtonPos = () => {
        setPosMaraton(true);
        setParMaraton(false);
    };
    const handleButtonPar = () => {
        setPosMaraton(false);
        setParMaraton(true);
    };

    const handleButtonPosfem = () => {
        setPosMaratonFem(true);
        setParMaratonFem(false);
    };
    const handleButtonParfem = () => {
        setPosMaratonFem(false);
        setParMaratonFem(true);
    };

    useEffect(() => {
        handleButtonPos();
        handleButtonPosfem();
    }, []);

    return (
        <>
            <h1 className='has-text-centered is-size-4 m-3'>Masculino</h1>
            <div className="contentbutton">
                <button className='tab-principal button is-small' onClick={handleButtonPos}>Posiciones</button>
                {/* <button className='tab-principal button is-small' onClick={handleButtonPar}>Participantes</button> */}
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Guía</a>
                </button>
            </div>
            {PosMar && <PosMaraton />}
            {ParMar && <ParMaraton />}
            <h1 className='has-text-centered is-size-4 m-3'>Femenino</h1>
            <div className="contentbutton">
                <button className='tab-principal button is-small' onClick={handleButtonPosfem}>Posiciones</button>
                {/* <button className='tab-principal button is-small' onClick={handleButtonParfem}>Participantes</button> */}
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Guía</a>
                </button>
            </div>
            {PosMarFem && <PosMaratonFem />}
            {ParMarFem && <ParMaratonFem />}
        </>
    )
}

export default IconoMaraton
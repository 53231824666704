import '../App.css';
import PosAjedrez from '../exel/PosAjedrez';
import ResAjedrez from '../exel/ResAjedrez';
import React, { useState, useEffect } from 'react';
import pdf from '../img/Reglamento-Ajedrez-OLIMPIADAS.pdf';

const IconoAjedrez = () => {
    const [Posaje, setPosAjedrez] = useState(false);
    const [Resaje, setResAjedrez] = useState(false);

    const handleButtonPos = () => {
        setPosAjedrez(true);
        setResAjedrez(false);
    }
    const handleButtonRes = () => {
        setPosAjedrez(false);
        setResAjedrez(true);
    }
    useEffect(() => {
        handleButtonPos();
    }, []);
    return(
        <>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonPos}>Posiciones</button>
                <button className='tab-principal button is-small' onClick={handleButtonRes}>Resultados</button>
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Reglamento</a>
                </button>
            </div>
            {Posaje && <PosAjedrez />}
            {Resaje && <ResAjedrez />}
            
        </>
    )
}

export default IconoAjedrez;
import IconPage from "../componentes/IconPage";
import padel from '../img/padel_dos.svg';
import { Layout } from "../layout";
import Encabezado from "../componentes/Encabezado";
import IconosVolver from "../componentes/IconosVolver";
import IconoPadel from "../componentes/IconoPadel";

const Padel = ({image, indice, height}) =>{

    return(
        <>
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
        <Encabezado/>
        <IconPage image={padel}/>
        <IconoPadel/>
        <IconosVolver to={'/'}/>
        </Layout>
        </>
    )
}

export default Padel
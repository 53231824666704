import IconPage from "../componentes/IconPage";
import ajedrez from "../img/ajedrez_dos.svg"
import { Layout } from "../layout";
import Encabezado from "../componentes/Encabezado";
import IconosVolver from "../componentes/IconosVolver";
import IconoAjedrez from "../componentes/IconoAjedraz";
const Ajedrez =()=>{

    return(
        <>
        <Layout textFooter={'federación argentina del trabajador de las universidades nacionales'}>
        <Encabezado/>
        <IconPage image={ajedrez}/>
        <IconoAjedrez/>
        <IconosVolver to={'/'}/>
        </Layout>
        </>
    )
}

export default Ajedrez
import '../App.css';
import FutbolM from '../exel/FutbolM';
import FutbolF from '../exel/FutbolF';
import Resultfem from '../exel/ResultF';
import Resultmas from '../exel/ResultM';
import PositionM from '../exel/PositionM';
import PositionF from '../exel/PositionF';

import React, { useState, useEffect } from 'react';
import pdf from '../img/Reglamento-Futbol5-OLIMPIADAS.pdf';

const IconoMixto = () => {
    const [Fixmas, setFixmas] = useState(false);
    const [Fixfem, setFixfem] = useState(false);
    const [Resmas, setResmas] = useState(false);
    const [Resfem, setResfem] = useState(false);
    const [Posmas, setPosmas] = useState(false);
    const [Posfem, setPosfem] = useState(false);
    const [Commas, setCommas] = useState(false);
    const [Comfem, setComfem] = useState(false);
// Botone fixtur
    const handleButtonMas = () => {
        setFixmas(true);
        setResmas(false);
        setPosmas(false);
        setCommas(false);
    };
    const handleButtonFem = () => {
        setFixfem(true);
        setResfem(false);
        setPosfem(false);
        setComfem(false);
    };
// Botones Resultados
    const handleButtonRMas = () => {
        setFixmas(false);
        setResmas(true);
        setPosmas(false);
        setCommas(false);
    };
    const handleButtonRFem = () => {
        setFixfem(false);
        setResfem(true);
        setPosfem(false);
        setComfem(false);
    };
// Botones Posiciones
    const handleButtonPMas = () => {
        setFixmas(false);
        setResmas(false);
        setPosmas(true);
        setCommas(false);
    };
    const handleButtonPFem = () => {
        setFixfem(false);
        setResfem(false);
        setPosfem(true);
        setComfem(false);
    };

    useEffect(() => {
        handleButtonRMas();
        handleButtonRFem();

    }, []);

    return (
        <>
            <h1 className='has-text-centered is-size-4 m-3'>Masculino</h1>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonRMas}>Resultados</button>
                <button className='tab-principal button is-small' onClick={handleButtonMas}>Fixture</button>
                <button className='tab-principal button is-small' onClick={handleButtonPMas}>Posiciones</button>
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Reglamento</a>
                </button>
            </div>
            {Fixmas && <FutbolM />}
            {Resmas && <Resultmas />}
            {Posmas && <PositionM />}
            
            <h1 className='has-text-centered is-size-4 m-3'>Femenino</h1>
            <div className='contentbutton'>
                <button className='tab-principal button is-small' onClick={handleButtonRFem}>Resultados</button>
                <button className='tab-principal button is-small' onClick={handleButtonFem}>Fixture</button>
                <button className='tab-principal button is-small' onClick={handleButtonPFem}>Posiciones</button>
                <button className='tab-principal button is-small'>
                    <a href={pdf} target="_blank" rel="noopener noreferrer">Reglamento</a>
                </button>
            </div>
            {Fixfem && <FutbolF />}
            {Resfem && <Resultfem />}
            {Posfem && <PositionF />}
           

        </>
    )

}

export default IconoMixto
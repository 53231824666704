import Encabezado from "../componentes/Encabezado";
import Footer from "../componentes/Footer";


import React from 'react'

export const Layout = ({ backgroundHeader = '', textFooter, children }) => {
  return (
    <main>
      {/* <Encabezado backgroundImage = {`url(${backgroundHeader})`}/> */}
      {children}
      
      <Footer texto={textFooter} />
    </main>
  )
}
